import React from 'react';

const AboutPage = () => {
  return (
    <div>
      <h1>About RentScout</h1>
      <p>This is a platform to search and add rental history...</p>
      {/* Add more content about your website here */}
    </div>
  );
};

export default AboutPage;
