// UnitPage.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const UnitPage = () => {
  const { placeId, unitNumber } = useParams();
  const [reviews, setReviews] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchUnitReviews = async () => {
        const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:3001'; // Fallback to localhost if the env variable is not set
        const response = await fetch(`${apiUrl}/api/reviews/${placeId}/${unitNumber}`);
      const data = await response.json();
      setReviews(data);
      setIsLoading(false);
    };

    fetchUnitReviews();
  }, [placeId, unitNumber]);

  if (isLoading) return <p>Loading...</p>;

  return (
    <div className="reviews">
      <h2>Reviews for Unit {unitNumber}</h2>
      <div style={{ marginTop: '30px' }}>
        {reviews.length ? (
          reviews.map(review => (
            <div key={review.id} style={{ 
              border: '1px solid black', 
              backgroundColor: 'lightgray', 
              padding: '10px', 
              marginBottom: '10px',
              maxWidth: '600px', 
              margin: '0 auto' 
            }}>
              <p>Address: {review.address}</p>
              <p>Unit #: {review.unit_number}</p>

              <p>Price: ${review.monthly_cost}</p>

              <p>Bedrooms: {review.bedrooms}</p>
              {/* Add more review details here as needed */}
            </div>
          ))
        ) : (
          <p>No reviews for this unit.</p>
        )}
      </div>
    </div>
  );
};

export default UnitPage;
