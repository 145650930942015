import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';

const ApartmentComplexPage = () => {
  const { placeId } = useParams();
  const [units, setUnits] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchUnits = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:3001'; // Fallback to localhost if the env variable is not set
        const response = await fetch(`${apiUrl}/api/units/${placeId}`);
        if (!response.ok) {
          throw new Error('Failed to fetch');
        }
        const data = await response.json();
        setUnits(data);
      } catch (error) {
        console.error('Error:', error);
      }
      setIsLoading(false);
    };

    fetchUnits();
  }, [placeId]);

  if (isLoading) return <p>Loading...</p>;

 
return (
  <div>
    <h2>Apartment Complex Page</h2>
    <div className="units-list">
      {units.length > 0 ? (
        units.map((unit, index) => (
          <Link key={index} to={`/apartments/${placeId}/units/${unit.unit_number}`}>
            Unit {unit.unit_number}
          </Link>
        ))
      ) : (
        <p>No units available.</p>
      )}
    </div>
  </div>
);

};

export default ApartmentComplexPage;
