import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import AddRentHistoryPage from './pages/AddRentHistoryPage';
import ApartmentComplexPage from './pages/ApartmentComplexPage'; // Import your ApartmentComplexPage
import UnitPage from './pages/UnitPage'; // Import UnitPage component
import './App.css';

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/add-rent-history" element={<AddRentHistoryPage />} />
        
        {/* Modify the Route for Apartment Complex Page */}
        <Route path="/apartments/:placeId" element={<ApartmentComplexPage />} />
        <Route path="/apartments/:placeId/units/:unitNumber" element={<UnitPage />} />

      </Routes>
    </Router>
  );
}

export default App;

