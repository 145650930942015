import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import skylineImage from '../assets/skyline.jpg';
import grassImage from '../assets/grass.jpg';


const HomePage = () => {


  const navigate = useNavigate();
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    // Function to load the Google Places script
    const loadGooglePlacesScript = () => {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places&callback=initAutocomplete`;
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
    };

    // Initialize Autocomplete once the script is loaded
    window.initAutocomplete = () => {
      /* global google */
      const autocomplete = new google.maps.places.Autocomplete(
        document.getElementById('autocomplete'), {types: ['geocode']}
      );

      // Event listener for place_changed event
      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        if (place.place_id) {
          // Use the useHistory hook to navigate
          navigate(`/apartments/${place.place_id}`);
        }
      });
    };

    const fetchReviews = async () => {
      try {

        console.log(`Using API URL: ${process.env.REACT_APP_API_URL}`);
        const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:3001';
        console.log(`Using API URL: ${apiUrl}`); // Add this line for debugging
        const response = await fetch(`${apiUrl}/api/reviews`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        let data = await response.json();
        // Sort reviews by timestamp in descending order
        data.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
        setReviews(data);
      } catch (error) {
        console.error('Error fetching reviews:', error);
      }
    };
    
    

    fetchReviews();
    loadGooglePlacesScript();
  }, [navigate]);

  return (
    <div>

<div className="header" style={{ textAlign: 'center', paddingBottom: '30px' }}>
  <img src={skylineImage} alt="Skyline" style={{ maxWidth: '100%', height: 'auto' }} />
  <h1 style={{ marginBottom: '10px' }}>Rentyel.com</h1>
  <p style={{ marginTop: '10px' }}>
    See what tenants have to say about a specific home or unit from previous experience by looking up the address below
  </p>
  <div>
    <input id="autocomplete" className="search-box" type="text" placeholder="Search by Address" />
  </div>
  <div style={{ marginTop: '10px' }}> {/* Adjust the top margin as needed */}
  {/* Use a button or span instead of <a> for SPA behavior */}
  <button
    className="link-style"
    onClick={() => navigate('/add-rent-history')}
    style={{ background: 'none', border: 'none', color: '#007bff', textDecoration: 'underline', cursor: 'pointer' }}
  >
    or create a review for a unit you live in now by clicking here
  </button>
</div>
</div>




  

<div className="reviews" style={{ backgroundColor: '#8cc63e' }}>
  <img src={grassImage} alt="Grass" style={{ maxWidth: '100%', height: 'auto' }} />

  <h2>Recent Reviews</h2>
  <div style={{ marginTop: '30px' }}>
    {reviews.slice(0, 5).map((review, index) => (
      <div key={index} className="review-box">
        <p>Place ID: {review.place_id}</p>
        <p>Address: {review.address}</p>
        <p>Timestamp: {new Date(review.timestamp).toLocaleString()}</p>
        {/* Add more fields here as needed */}
      </div>
    ))}
  </div>
</div>



      
    </div>
  );
  
  
};

export default HomePage
