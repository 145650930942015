import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
//mport MyRatingComponent from '../components/MyRatingComponent'; // This path should be correct based on your project structure
import Rating from 'react-rating';

const AddRentHistoryPage = () => {
  //const [isWholeBuilding, setIsWholeBuilding] = useState(false);
  const [isAddressFilled, setIsAddressFilled] = useState(false);
  const [placeId, setPlaceId] = useState('');
  const [address, setAddress] = useState('');

  const navigate = useNavigate();

/*
  const handleCheckboxChange = () => {
    setIsWholeBuilding(!isWholeBuilding);
  };*/

  const handleClearClick = () => {
    setIsAddressFilled(false);
    setPlaceId('');
    setAddress('');
  };

  const [unitNumber, setUnitNumber] = useState('');
  const [bedrooms, setBedrooms] = useState('');
  const [baths, setBaths] = useState('');
  const [monthlyCost, setMonthlyCost] = useState('');
  const [startMonth, setStartMonth] = useState('');
  const [startYear, setStartYear] = useState('');
  const [endMonth, setEndMonth] = useState('');
  const [endYear, setEndYear] = useState('');

  useEffect(() => {
    const loadGooglePlacesScript = () => {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places&callback=initAutocomplete`;
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
    };

    window.initAutocomplete = () => {
      /* global google */
      const autocomplete = new google.maps.places.Autocomplete(
        document.getElementById('autocomplete-address'), { types: ['geocode'] }
      );

      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        if (place.place_id) {
          setPlaceId(place.place_id);
          setIsAddressFilled(true);
          setAddress(place.formatted_address);
        }
      });
    };

    loadGooglePlacesScript();
  }, []);

  

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    const rentHistoryData = {
      placeId,
      address,
      unitNumber,
      bedrooms,
      baths,
      monthlyCost,
      startMonth,
      startYear,
      endMonth,
      endYear,
      // ... other data fields ...
    };
  
    try {
      const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:3001'; // Fallback to localhost if the env variable is not set
        const response = await fetch(`${apiUrl}/api/rent-history`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(rentHistoryData),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      console.log(data);
      // Handle the response or redirect the user

      navigate('/');
    } catch (error) {
      console.error('Error posting rent history:', error);
    }
  };
  

  const months = [
    'January', 'February', 'March', 'April',
    'May', 'June', 'July', 'August',
    'September', 'October', 'November', 'December'
  ];

  const currentYear = new Date().getFullYear();
const years = Array.from({ length: currentYear - 1999 }, (_, index) => 2000 + index);

  return (
    <div>
      <div className="form-container" style={{ width: '40%', margin: '0 auto' }}>
      <h2 style={{ textAlign: 'center', fontSize: '2.5em' }}>Add your Rent History</h2> {/* Increased font size for the heading */}
  <p style={{ textAlign: 'center', fontSize: '1.25em' }}> {/* Increased font size for the paragraph */}
    This review will not be tied to an account since you are not logged in. You will not be able to edit or delete this review.
  </p>
  
        <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
            <input 
              id="autocomplete-address" 
              type="text" 
              className="form-input" 
              style={{ 
                flex: 1, 
                padding: '12px 15px', // Increased padding
                fontSize: '1.4em', // Larger font size
                borderRadius: '4px', 
                border: '1px solid #ddd',
                width: '100%', // You can adjust the width as necessary
              }} 
              placeholder="Address" 
              required 
              readOnly={isAddressFilled} 
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
            {isAddressFilled && (
              <button type="button" onClick={handleClearClick} style={{ marginLeft: '10px', padding: '8px 12px', borderRadius: '4px', border: 'none', backgroundColor: '#e74c3c', color: 'white', cursor: 'pointer' }}>
                Clear
              </button>
            )}
          </div>
  
          {isAddressFilled && (
  <>

<Rating
      emptySymbol={<span className="icon-text">-</span>}
      fullSymbol={[1, 2, 3, 4, 5].map((n) => (
        <span key={n} className="icon-text">{n}</span>
      ))}
    />



    <div className="form-input-container">
                <input 
                  type="text" 
                  className="form-input" 
                  placeholder="Unit #" 
                  value={unitNumber}
                  onChange={(e) => setUnitNumber(e.target.value)}
                  required
                  style={{ padding: '8px 12px', borderRadius: '4px', border: '1px solid #ddd' }} 
                />
              </div>

    <div className="form-input-container">
      <input 
        type="text" 
        className="form-input small" 
        placeholder="Bedrooms" 
        value={bedrooms}
        onChange={(e) => setBedrooms(e.target.value)}
        required
        style={{ padding: '8px 12px', borderRadius: '4px', border: '1px solid #ddd' }}  
      />
    </div>

    <div className="form-input-container">
      <input 
        type="text" 
        className="form-input small" 
        placeholder="# Baths" 
        value={baths}
        onChange={(e) => setBaths(e.target.value)}
        required 
        style={{ padding: '8px 12px', borderRadius: '4px', border: '1px solid #ddd' }} 
      />
    </div>

    <div className="form-input-container">
      <input 
        type="text" 
        className="form-input" 
        placeholder="$ Monthly Cost (Should Include Fixed Costs)" 
        value={monthlyCost}
        onChange={(e) => setMonthlyCost(e.target.value)}
        required 
        style={{ padding: '8px 12px', borderRadius: '4px', border: '1px solid #ddd' }} 
      />
    </div>

    <div className="form-input-container">
  <div className="date-container">
    <select 
      className="form-select small" 
      value={startMonth}
      onChange={(e) => setStartMonth(e.target.value)}
      defaultValue=""
    >
      <option value="" disabled>Start Month</option>
      {months.map((month, index) => (
        <option key={index} value={month}>{month}</option>
      ))}
    </select>
    <select 
      className="form-select small" 
      value={startYear}
      onChange={(e) => setStartYear(e.target.value)}
      defaultValue=""
    >
      <option value="" disabled>Start Year</option>
      {years.map((year, index) => (
        <option key={index} value={year}>{year}</option>
      ))}
    </select>
  </div>
</div>

<div className="form-input-container">
  <div className="date-container">
    <select 
      className="form-select small" 
      value={endMonth}
      onChange={(e) => setEndMonth(e.target.value)}
      defaultValue=""
    >
      <option value="" disabled>End Month</option>
      {months.map((month, index) => (
        <option key={index} value={month}>{month}</option>
      ))}
    </select>
    <select 
      className="form-select small" 
      value={endYear}
      onChange={(e) => setEndYear(e.target.value)}
      defaultValue=""
    >
      <option value="" disabled>End Year</option>
      {years.map((year, index) => (
        <option key={index} value={year}>{year}</option>
      ))}
    </select>
  </div>
</div>


    <div className="form-input-container">
      <button type="submit" className="submit-btn">
        SUBMIT
      </button>
    </div>
  </>


)}        
        
        </form>
      </div>
    </div>
  );
  
};

export default AddRentHistoryPage;
